
<template>
  <div>
    <button v-if="!walletConnected" @click="connectWallet">Connect Phantom Wallet</button>
    <div >
      <div>
        <div v-if="wallet && wallet.publicKey">
          Public Key: {{ wallet.publicKey.toString() }}
          <div v-if="isUserReferrer">
            <div v-for="mint in bettingProject.mints" :key="mint.mint">
              <button
                :disabled="isReferrerRewardClaimed(mint.mint)"
                @click="claimReferReward(mint.mint)"
              >
                Claim Refer Reward for {{ mint.mint }}
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          Please connect your wallet.
        </div>
      </div>
      <h1>{{ bettingProject.title }}</h1>
      <!-- If the user is a referrer, show the "Claim Refer Reward" button -->
      <!-- 将 v-if 移到一个父级 div 上 -->
      



      <p>{{ bettingProject.content }}</p>
      <p>开始时间: {{ formattedStartTime }}</p>
      <p>结束时间: {{ formattedEndTime }}</p>
      <p v-if="bettingProject.isOpen">项目正在进行中</p>
      <p v-else>项目已关闭</p>
      <div v-for="(option, index) in bettingProject.options" :key="index">
        <h3>{{ option.title }}</h3>
        <p>{{ option.content }}</p>
        <p>最小投注: {{ option.minBet }}</p>
        <p>最大投注: {{ option.maxBet }}</p>
        <p v-for="(total, mint) in calculateTotalBetForOption(index)" :key="mint">
          总投注 (Mint {{ mint }}): {{ total }} SOL
        </p>
        <!-- 遍历 userBetSummary 以显示每个 mint 的投注金额 -->
        <div v-for="(amount, mint) in userBetSummary" :key="mint">
          <p>您的投注金额 (Mint {{ mint }}): {{ amount[index] || 0 }}</p>
          <p v-if="amount">
            如果选项 {{ optionId }} 获胜，您将获得: {{ calculateReward(mint,index) }} {{ mint }} tokens
          </p>
  
          <!-- 只有在用户未领取奖励且该选项获胜时，显示 "Claim Reward" 按钮 -->
          <button
          v-if="bettingProject.winningOption === index && amount[index]"
          :disabled="isRewardClaimed(mint)"
          @click="claimReward(mint)"
        >
          Claim Reward
        </button>
        <p v-if="isRewardClaimed(mint) && bettingProject.winningOption === index">
          已领取奖励: {{ calculateReward(mint) }} SOL
        </p>
        </div>
        <input v-model="betAmount[index]" type="number" placeholder="Enter bet amount" />
        <input v-model="tokenAddress[index]" type="string" placeholder="Enter mint string" />
        <input v-model="referrer[index]" type="string" placeholder="Enter referrer string" />
        <button @click="placeBet(index)">Place Bet</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { nextTick } from 'vue';
import { computed } from 'vue';
import * as anchor from '@project-serum/anchor';
import { Connection, PublicKey,Transaction } from '@solana/web3.js';
import { Buffer } from 'buffer';
import {  TOKEN_PROGRAM_ID,ASSOCIATED_TOKEN_PROGRAM_ID,getAssociatedTokenAddress,createAssociatedTokenAccountInstruction } from '@solana/spl-token';
window.Buffer = Buffer;

const walletConnected = ref(false);
const wallet = ref(null);
const bettingProject = ref({
  title: '',
  content: '',
  startTime: 0,
  endTime: 0,
  isOpen: false,
  winningOption:'',
  options: []
});
const betAmount = ref([]);
const tokenAddress=ref([]);
const referrer=ref([]);
const formattedStartTime = ref('');
const formattedEndTime = ref('');
let BETTING_PROJECT_PUBKEY='';
const default_project = new PublicKey('D8z4Poa46yCHEzZAxRvxJxf88Ly7uM8FEAiYT5ggBvvX');
const PROGRAM_ID = new PublicKey('Hv8FkdMwZcoovBTZMcfuFQvjMV6L8ZnTJ8Jgd6VA3fp5');
let bettingProjectTokenAccount = '';
//let bettingProjectTokenAccount = '';
const tokenString="6bNHEzYEYX1SexpbURBp5tGLuhFUEYxhstrsyiMLwyCs";
const tokenNative="So11111111111111111111111111111111111111112";
const defaultReferrer="Hyp6vERLdYZBogFrMChnsKwUo8f3JJy3cAsq2GKgbVGM";
const bettingProjectData = ref(null); // 用于存储fetchBettingProjectData中获取的数据
const userBetSummary = ref({}); // 使用 ref 来创建响应式对象
const idl = ref(null);
const connection = new Connection('https://api.devnet.solana.com', 'confirmed');
// 公共方法提取
const getProvider = () => new anchor.AnchorProvider(connection, wallet.value, anchor.AnchorProvider.defaultOptions());
const getProgram = () => new anchor.Program(idl.value, PROGRAM_ID, getProvider());




const checkWalletConnection = async () => {
  if (window.solana) {
    try {
      await window.solana.connect();
      wallet.value = window.solana;
      walletConnected.value = true;
      console.log("Connected with Public Key:", wallet.value.publicKey.toString());
      // 这里可以加载项目数据，或者进行其他需要钱包连接后的操作
      await fetchBettingProjectData();
    } catch (err) {
      console.error("Error connecting wallet:", err);
    }
  } else {
    alert("Please install Phantom wallet");
  }
};
onMounted(async () => {
  

  const urlParams = new URLSearchParams(window.location.search);
  const bettingProject= urlParams.get('project');
  if (bettingProject) {
    
    BETTING_PROJECT_PUBKEY=new PublicKey(bettingProject);
    
  }else{
    BETTING_PROJECT_PUBKEY=default_project;
  }


  bettingProjectTokenAccount = await getAssociatedTokenAddress(
        new PublicKey(tokenString),
        BETTING_PROJECT_PUBKEY,
        true
    );
  console.log('Associated Token Account:', bettingProjectTokenAccount.toString());

  
  try {
        // 获取与项目相关的代币账户
    console.log(tokenString);  // 这里的 tokenString 应该是代币的 mint 地址
    console.log(BETTING_PROJECT_PUBKEY.toString());

    const response = await fetch('../IDL/mainidl1027.json');
    idl.value = await response.json();
    console.log('11111');
    console.log(idl.value);
    await fetchBettingProjectData();
  } catch (error) {
    console.error('Failed to load IDL:', error);
  }
});
/*
const getUserBetAmount = (mint, optionId) => {
  return userBetSummary.value[mint]?.[optionId] || 0; // 返回用户在该选项上的投注金额，默认是 0
};
*/

const updateUserBetSummary = (walletPublicKey, bettingProjectData) => {
  if (bettingProjectData) {
    const userBets = bettingProjectData.bets.filter(bet => {
      const isMatch = bet.user.toString() === walletPublicKey.toString();
      if (!isMatch) {
        console.log(`No match for Bet User: ${bet.user} with Wallet Public Key: ${walletPublicKey.toString()}`);
      }
      return isMatch;
    });

    console.log("User Bets:", userBets);
    console.log("Winning option:", bettingProjectData.winningOption);

    const summary = {};
    userBets.forEach(bet => {
      const optionId = bet.optionId;
      const amount = parseInt(bet.amount, 10); // 获取原始 lamports 金额
      const mint = bet.mint;

      // 创建嵌套对象以按 mint 和 optionId 汇总
      if (!summary[mint]) {
        summary[mint] = {};
      }
      
      if (summary[mint][optionId]) {
        summary[mint][optionId] += amount;
      } else {
        summary[mint][optionId] = amount;
      }
    });

    // 将汇总的金额转换为 SOL 单位（假设原始金额是以 lamports 计量）
    for (const mint in summary) {
      for (const optionId in summary[mint]) {
        const amountInLamports = summary[mint][optionId];
        const amountInSOL = (amountInLamports / 1000000000).toFixed(2); // lamports 转换为 SOL
        summary[mint][optionId] = amountInSOL;
        console.log(`Mint ${mint}, Option ${optionId}: Total Bet Amount = ${amountInSOL} SOL`);
      }
    }

    userBetSummary.value = summary; // 更新用户投注汇总
    console.log("User Bet Summary:", JSON.stringify(userBetSummary.value, null, 2));
  }
};


const connectWallet = async () => {

  if (window.solana) {
    console.log(window.solana);
    try {
      await window.solana.connect();
      wallet.value = window.solana;
      walletConnected.value = true;
      console.log("Connected with Public Key:", wallet.value.publicKey.toString());

      updateUserBetSummary(wallet.value.publicKey, bettingProjectData.value);
    } catch (err) {
      console.error("Error connecting wallet:", err);
    }
  } else {
    alert("Please install Phantom wallet");
  }
};

// 判断用户是否在推荐人列表中
const isUserReferrer = computed(() => {
  if (!wallet.value || !wallet.value.publicKey || !bettingProject.value.referrers) {
    return false;
  }
  return bettingProject.value.referrers.some(
    referrer => referrer.key === wallet.value.publicKey.toString()
  );
});

// 判断推荐奖励是否已被领取
const isReferrerRewardClaimed = (mint) => {
  if (!wallet.value || !wallet.value.publicKey || !bettingProject.value.claimedReferrers) {
    return false;
  }

  console.log("Wallet Public Key:", wallet.value.publicKey.toString());
  console.log("Mint:", mint);
  console.log("Claimed Referrers:", bettingProject.value.claimedReferrers);

  return bettingProject.value.claimedReferrers.some((claimedReferrer) => {
    console.log("Checking claimedReferrer:", claimedReferrer);
    const userMatch = claimedReferrer.user === wallet.value.publicKey.toString();
    const mintMatch = claimedReferrer.mint === mint;
    console.log("User Match:", userMatch);
    console.log("Mint Match:", mintMatch);
    return userMatch && mintMatch;
  });
};





const fetchBettingProjectData = async () => {
  const program = getProgram();

  try {
    const data = await program.account.bettingProject.fetch(BETTING_PROJECT_PUBKEY);
    bettingProjectData.value = data;

    // 更新 bettingProject 对象
    bettingProject.value = {
      title: data.title,
      content: data.content,
      startTime: data.startTime.toNumber(),
      endTime: data.endTime.toNumber(),
      isOpen: data.isOpen,
      winningOption: data.winningOption,
      options: data.options.map(option => ({
        title: option.title,
        content: option.content,
        minBet: (option.minBet / 1000000000).toString(),
        maxBet: (option.maxBet / 1000000000).toString(),
      })),
      mints: data.mints.map(mint => ({
        mint: mint.mint.toString(),
        amount: mint.amount.toString(),
      })),
      bets: data.bets.map(bet => ({
        user: bet.user.toString(),
        optionId: bet.optionId,
        amount: bet.amount.toString(),
        mint: bet.mint.toString(),
        referrer: bet.referrer ? bet.referrer.toString() : null,
      })),
      claimedUsers: data.claimedUsers.map(user => ({
        user: user.user.toString(),
        mint: user.mint.toString(),
      })),
      referrers: data.referrers.map(referrer => ({
        key: referrer.key.toString(),
        commissionPercentage: referrer.commissionPercentage,
      })),
      claimedReferrers:data.claimedReferrers.map(claimedReferrer => ({
        user: claimedReferrer.key.toString(),
        mint: claimedReferrer.mint.toString(),
      })),
    };
    await nextTick();

    // 格式化时间
    formattedStartTime.value = new Date(bettingProject.value.startTime * 1000).toLocaleString();
    formattedEndTime.value = new Date(bettingProject.value.endTime * 1000).toLocaleString();

    // 设置推荐人逻辑
    const urlParams = new URLSearchParams(window.location.search);
    const referrerParam = urlParams.get('referrer');
    
    if (bettingProject.value && bettingProject.value.options) {
      // 根据 referrer 参数设置推荐人
      bettingProject.value.options.forEach((option, index) => {
        if (referrerParam) {
          // 如果有 URL 中的推荐人参数，使用它
          referrer.value[index] = referrerParam;
        } else {
          // 如果没有推荐人参数，使用默认推荐人
          referrer.value[index] = defaultReferrer;
        }
      });
    } else {
      console.error('bettingProject or bettingProject.options is undefined');
    }
    if (wallet.value && wallet.value.publicKey){
      updateUserBetSummary(wallet.value.publicKey, bettingProjectData.value);
    }
    
    

    console.log("Betting project data fetched and processed:", bettingProject.value);
  } catch (err) {
    console.error("Error fetching betting project data:", err);
  }
};
const calculateTotalBetForOption = (optionIndex) => {
  // 初始化一个空对象，用于存储不同 mint 下的总投注金额
  const totalBetsByMint = {};

  // 遍历所有的投注记录
  bettingProject.value.bets.forEach(bet => {
    // 如果该投注属于当前选项
    if (bet.optionId === optionIndex) {
      const mint = bet.mint;
      const amount = Number(bet.amount);

      // 如果该 mint 尚未有投注金额，初始化为 0
      if (!totalBetsByMint[mint]) {
        totalBetsByMint[mint] = 0;
      }

      // 累加投注金额
      totalBetsByMint[mint] += amount;
    }
  });

  // 将总金额转换为 SOL 单位（假设原始金额是以 lamports 计量）
  Object.keys(totalBetsByMint).forEach(mint => {
    totalBetsByMint[mint] = (totalBetsByMint[mint] / 1000000000).toFixed(2);
  });

  return totalBetsByMint;
};



const placeBet = async (optionIndex) => {
  if (!walletConnected.value) {
    await checkWalletConnection();
  }
  const program = getProgram();

  const inputAmount = betAmount.value[optionIndex];
  const token = tokenAddress.value[optionIndex];
  const referrerPbulicKey = new PublicKey(referrer.value[optionIndex]);
  const tokenPublicKey = new PublicKey(token);
  const amount = Math.floor(inputAmount * Math.pow(10, 9));  // 乘以 10^9 进行转换

  if (!amount || isNaN(amount)) {
    alert("Please enter a valid bet amount");
    return;
  }

  try {
    let signerTokenAccount;

    // 如果是Solana原生代币，直接使用钱包地址作为Token账户
    if (token === tokenNative) {
      const fixToken=tokenString;
      const fixTokenPublic = new PublicKey(fixToken);
      signerTokenAccount = await getAssociatedTokenAddress(
        fixTokenPublic,
        wallet.value.publicKey
      );

      console.log('该币的关联账户：' + signerTokenAccount.toString());
    } else {
      // 获取或创建Token账户
      signerTokenAccount = await getAssociatedTokenAddress(
        tokenPublicKey,
        wallet.value.publicKey
      );

      console.log('该币的关联账户：' + signerTokenAccount.toString());

      // 检查关联Token账户是否已存在
      const accountInfo = await connection.getAccountInfo(signerTokenAccount);
      if (!accountInfo) {
        const userConfirmed = confirm("您尚未创建关联的 Token 账户。是否要创建该账户？");
        if (userConfirmed) {
          const transaction = new Transaction().add(
            createAssociatedTokenAccountInstruction(
              wallet.value.publicKey,
              signerTokenAccount,
              wallet.value.publicKey,
              tokenPublicKey,
              TOKEN_PROGRAM_ID,
              ASSOCIATED_TOKEN_PROGRAM_ID
            )
          );

          // 获取最新的 blockhash 并设置 feePayer
          const { blockhash } = await connection.getLatestBlockhash();
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = wallet.value.publicKey;

          // 签署并发送交易
          const signedTransaction = await wallet.value.signTransaction(transaction);
          const signature = await connection.sendRawTransaction(signedTransaction.serialize());
          console.log("关联 Token 账户创建成功，交易签名:", signature);
        } else {
          alert("请先创建关联 Token 账户。");
          return;
        }
      }

      
    }
    // 检查用户的余额是否足够
    const tokenBalance = await connection.getTokenAccountBalance(signerTokenAccount);
      console.log('余额是：'+tokenBalance.value.uiAmount+'提交值是：'+amount);
      if (tokenBalance.value.uiAmount*1000000000 < amount) {
        alert("您的Token余额不足。");
        return;
      }

    // 放置赌注
    await program.methods
      .placeBet(optionIndex, new anchor.BN(amount), tokenPublicKey,referrerPbulicKey)
      .accounts({
        bettingProject: BETTING_PROJECT_PUBKEY,
        signer: wallet.value.publicKey,
        signerTokenAccount: signerTokenAccount,
        systemProgram: anchor.web3.SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        bettingProjectTokenAccount: bettingProjectTokenAccount,
      })
      .signers([])
      .rpc();

    alert("Bet placed successfully!");
    await fetchBettingProjectData(); // 放置赌注后刷新数据
  } catch (err) {
    console.error("Error placing bet:", err);
    
    // 提取错误消息并显示在 alert 中
    const errorMessage = err.message || "An unknown error occurred.";
    alert(`Error placing bet: ${errorMessage}`);
    
  }
};

const claimReward = async (mint) => {
  if (!walletConnected.value) {
    await checkWalletConnection(); // 检查是否连接了钱包
  }

  const program = getProgram();


  let userTokenAccount;
  if (mint === tokenNative) {
    const fixToken=tokenString;
    const fixTokenPublic = new PublicKey(fixToken);
    userTokenAccount = await getAssociatedTokenAddress(
      fixTokenPublic,
      wallet.value.publicKey
    );

  } else {
    userTokenAccount = await getAssociatedTokenAddress(new PublicKey(mint), wallet.value.publicKey); // 获取SPL Token账户
  }

  try {
    const transactionSignature = await program.methods
      .claimReward(new PublicKey(mint))
      .accounts({
        bettingProject: BETTING_PROJECT_PUBKEY,
        user: wallet.value.publicKey,
        tokenProgram: anchor.web3.TOKEN_PROGRAM_ID,
        bettingProjectTokenAccount: bettingProjectTokenAccount,
        userTokenAccount: userTokenAccount,
      })
      .rpc({ commitment: 'confirmed' });

    console.log('Claim reward transaction successful:', transactionSignature);
    alert('Claim reward successful!');
  } catch (err) {
    console.error('Failed to claim reward:', err);
    alert(`Failed to claim reward: ${err.message}`);
  }
};

const claimReferReward = async (mint) => {
  if (!walletConnected.value) {
    await checkWalletConnection(); // 检查是否连接了钱包
  }

  const program = getProgram();

  let userTokenAccount;
  if (mint === tokenNative) {
    const fixToken = tokenString;
    const fixTokenPublic = new PublicKey(fixToken);
    userTokenAccount = await getAssociatedTokenAddress(
      fixTokenPublic,
      wallet.value.publicKey
    );
  } else {
    userTokenAccount = await getAssociatedTokenAddress(new PublicKey(mint), wallet.value.publicKey);
  }

  try {
    const transactionSignature = await program.methods
      .claimReferReward(new PublicKey(mint)) // 调用 claimReferReward 方法
      .accounts({
        bettingProject: BETTING_PROJECT_PUBKEY,
        user: wallet.value.publicKey,
        tokenProgram: anchor.web3.TOKEN_PROGRAM_ID,
        bettingProjectTokenAccount: bettingProjectTokenAccount,
        userTokenAccount: userTokenAccount,
      })
      .rpc({ commitment: 'confirmed' });

    console.log('Claim refer reward transaction successful:', transactionSignature);
    alert('Claim refer reward successful!');
  } catch (err) {
    console.error('Failed to claim refer reward:', err);
    alert(`Failed to claim refer reward: ${err.message}`);
  }
};

const isRewardClaimed = (mint) => {
  if (!wallet.value || !wallet.value.publicKey || !bettingProject.value.claimedUsers) {
    return false;
  }
  return bettingProject.value.claimedUsers.some(
    (claimedUser) =>
      claimedUser.user === wallet.value.publicKey.toString() &&
      claimedUser.mint === mint
  );
};
const calculateReward = (mint, whichwin) => {
  // 获取用户对指定 option 的投注总金额
  console.log("计算："+whichwin);
  const userBets = bettingProject.value.bets.filter(
    (bet) => bet.user === wallet.value.publicKey.toString() && 
             bet.mint === mint && 
             bet.optionId === whichwin
  );
  console.log("User bets for option:", userBets);

  // 用户的总投注金额
  const totalUserBet = userBets.reduce((sum, bet) => sum + Number(bet.amount), 0);
  console.log("Total user bet amount for winning option:", totalUserBet);

  // 获取 winningOption 的总投注量
  const totalWinningBets = bettingProject.value.bets
    .filter((bet) => bet.optionId === whichwin  && bet.mint === mint)
    .reduce((sum, bet) => sum + Number(bet.amount), 0);
  console.log("Total winning option bet amount:", totalWinningBets);

  // 获取奖励池中的总金额 (单位：SOL)
  const totalPrizePool = bettingProject.value.mints.find(m => m.mint === mint)?.amount || 0;
  console.log("Total prize pool for mint:", totalPrizePool);

  // 奖励的计算逻辑
  const userReward = (totalUserBet / totalWinningBets) * totalPrizePool;
  console.log("Calculated user reward:", userReward);

  // 返回计算出的奖励金额，单位 SOL
  return (userReward / 1000000000).toFixed(6);
};





</script>
