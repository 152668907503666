
<template>
  <div id="app">
    <nav>
      <router-link to="/betting">前往投注页面</router-link>
    </nav>
    <router-view />
  </div>
  
</template>

<script setup>

</script>
